var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', [_c('validation-observer', {
    ref: "refFormObserver",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var handleSubmit = _ref.handleSubmit,
          invalid = _ref.invalid,
          pristine = _ref.pristine;
        return [_c('b-card', {
          staticClass: "border",
          attrs: {
            "header-tag": "header",
            "border-variant": "info",
            "header-bg-variant": "light-info",
            "header-class": "py-1"
          },
          scopedSlots: _vm._u([{
            key: "header",
            fn: function fn() {
              return [_c('div', {
                staticClass: "d-flex align-items-center justify-content-between w-100"
              }, [_c('h5', {
                staticClass: "m-0"
              }, [_vm._v(" " + _vm._s(_vm.$t('employee.empInfo')) + " ")]), _c('div', {
                staticClass: "d-flex gap-2"
              }, [false ? _c('b-button', {
                staticClass: "mr-1",
                attrs: {
                  "variant": "info"
                },
                on: {
                  "click": function click($event) {
                    return _vm.showRoleModal($event.target);
                  }
                }
              }, [_c('span', {}, [_vm._v(_vm._s(_vm.$t('employee.role')))])]) : _vm._e(), _vm.canResetPaymentPassword ? _c('b-button', {
                attrs: {
                  "variant": "outline-danger"
                },
                on: {
                  "click": _vm.handleResetPaymentPassword
                }
              }, [_c('span', {}, [_vm._v(_vm._s(_vm.$t('myAccount.paymentPassword.reset')))])]) : _vm._e(), _vm.readonly ? _c('b-button', {
                attrs: {
                  "variant": "info",
                  "disabled": !_vm.canAccess('employee.updateEmployee')
                },
                on: {
                  "click": _vm.handleEdit
                }
              }, [_c('span', {}, [_vm._v(_vm._s(_vm.$t('edit')))])]) : _c('b-button', {
                attrs: {
                  "variant": "outline-info",
                  "disabled": !_vm.canAccess('employee.updateEmployee')
                },
                on: {
                  "click": _vm.handleCancelEdit
                }
              }, [_c('span', {}, [_vm._v(_vm._s(_vm.$t('cancelEdit')))])])], 1), _vm.employeeData ? _c('EmployeesRoleModal', {
                attrs: {
                  "employee-data": _vm.employeeData,
                  "is-show-modal": _vm.isShowModalEmployeesRole
                }
              }) : _vm._e()], 1)];
            },
            proxy: true
          }], null, true)
        }, [_c('b-card-body', {
          staticClass: "p-0 pt-1"
        }, [_c('b-form', [_c('b-row', [_c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "".concat(_vm.$t('employee.userName')),
            "label-for": "user-name"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "user-name",
            "value": _vm.employeeToEdit.username.toUpperCase(),
            "disabled": ""
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "".concat(_vm.$t('employee.empCode')),
            "label-for": "emp-code"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "emp-code",
            "disabled": ""
          },
          model: {
            value: _vm.employeeToEdit.employeeCode,
            callback: function callback($$v) {
              _vm.$set(_vm.employeeToEdit, "employeeCode", $$v);
            },
            expression: "employeeToEdit.employeeCode"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Last Name",
            "rules": "required|max:30"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "lastName"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('employee.lastName')) + " "), _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v(_vm._s(_vm.isEditActive))])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                attrs: {
                  "id": "lastName",
                  "disabled": _vm.readonly,
                  "placeholder": _vm.$t('employee.placeholderLastName'),
                  "maxlength": "30",
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "lazy-formatter": "",
                  "formatter": _vm.trimInput
                },
                model: {
                  value: _vm.employeeToEdit.lastName,
                  callback: function callback($$v) {
                    _vm.$set(_vm.employeeToEdit, "lastName", $$v);
                  },
                  expression: "employeeToEdit.lastName"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "First Name",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "firstName"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('employee.firstName')) + " "), _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v(_vm._s(_vm.isEditActive))])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                attrs: {
                  "id": "firstName",
                  "disabled": _vm.readonly,
                  "placeholder": _vm.$t('employee.placeholderFirstName'),
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "lazy-formatter": "",
                  "formatter": _vm.trimInput
                },
                model: {
                  value: _vm.employeeToEdit.firstName,
                  callback: function callback($$v) {
                    _vm.$set(_vm.employeeToEdit, "firstName", $$v);
                  },
                  expression: "employeeToEdit.firstName"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('employee.phoneNumber'),
            "rules": "required|phoneNumber"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "phoneNumber"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('employee.phoneNumber')) + " "), _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v(_vm._s(_vm.isEditActive))])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                attrs: {
                  "id": "phoneNumber",
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "disabled": _vm.readonly,
                  "lazy-formatter": "",
                  "formatter": _vm.trimInput,
                  "debounce": 300,
                  "placeholder": _vm.$t('employee.placeholderPhoneNumber')
                },
                model: {
                  value: _vm.employeeToEdit.phoneNumber,
                  callback: function callback($$v) {
                    _vm.$set(_vm.employeeToEdit, "phoneNumber", $$v);
                  },
                  expression: "employeeToEdit.phoneNumber"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('employee.email'),
            "rules": "required|email"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "email"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('employee.email')) + " "), _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v(_vm._s(_vm.isEditActive))])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                attrs: {
                  "id": "email",
                  "maxlength": "50",
                  "disabled": _vm.readonly,
                  "placeholder": _vm.$t('employee.placeholderEmail'),
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "lazy-formatter": "",
                  "formatter": _vm.trimInput,
                  "debounce": 300
                },
                model: {
                  value: _vm.employeeToEdit.emailAddress,
                  callback: function callback($$v) {
                    _vm.$set(_vm.employeeToEdit, "emailAddress", $$v);
                  },
                  expression: "employeeToEdit.emailAddress"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('employee.gender'),
            "rules": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label": _vm.$t('employee.gender'),
                  "label-for": "gender-type"
                }
              }, [_c('v-select', {
                attrs: {
                  "label": "label",
                  "options": _vm.genderOptions,
                  "reduce": function reduce(val) {
                    return val.value;
                  },
                  "clearable": false,
                  "disabled": _vm.readonly,
                  "input-id": "gender-type"
                },
                scopedSlots: _vm._u([{
                  key: "option",
                  fn: function fn(data) {
                    return [_c('span', [_vm._v(" " + _vm._s(_vm.$te(data.label) ? _vm.$t(data.label) : data.label) + " ")])];
                  }
                }, {
                  key: "selected-option",
                  fn: function fn(data) {
                    return [_c('span', [_vm._v(" " + _vm._s(_vm.$te(data.label) ? _vm.$t(data.label) : data.label) + " ")])];
                  }
                }, {
                  key: "no-options",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.employeeToEdit.gender,
                  callback: function callback($$v) {
                    _vm.$set(_vm.employeeToEdit, "gender", $$v);
                  },
                  expression: "employeeToEdit.gender"
                }
              }), _c('b-form-invalid-feedback', {
                attrs: {
                  "state": _vm.getValidationState(validationContext)
                }
              }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('employee.employeeType'),
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "employee-type",
                  "state": _vm.getValidationState(validationContext)
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('employee.employeeType')) + " "), _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v(_vm._s(_vm.isEditActive))])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('v-select', {
                attrs: {
                  "options": _vm.isADM ? _vm.typeOfEmployeeOptions : _vm.typeOfEmployeeOptionsKhacADM,
                  "reduce": function reduce(val) {
                    return val.value;
                  },
                  "clearable": false,
                  "disabled": _vm.readonly || _vm.isADM && _vm.meData.id === _vm.employeeData.id,
                  "input-id": "employee-type"
                },
                scopedSlots: _vm._u([{
                  key: "option",
                  fn: function fn(data) {
                    return [_c('span', [_vm._v(" " + _vm._s(_vm.$te(data.label) ? _vm.$t(data.label) : data.label) + " ")])];
                  }
                }, {
                  key: "selected-option",
                  fn: function fn(data) {
                    return [_c('span', [_vm._v(" " + _vm._s(_vm.$te(data.label) ? _vm.$t(data.label) : data.label) + " ")])];
                  }
                }, {
                  key: "no-options",
                  fn: function fn(_ref2) {
                    var search = _ref2.search,
                      searching = _ref2.searching,
                      loading = _ref2.loading;
                    return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
                  }
                }], null, true),
                model: {
                  value: _vm.employeeToEdit.type,
                  callback: function callback($$v) {
                    _vm.$set(_vm.employeeToEdit, "type", $$v);
                  },
                  expression: "employeeToEdit.type"
                }
              }), _c('b-form-invalid-feedback', {
                attrs: {
                  "state": _vm.getValidationState(validationContext)
                }
              }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Agency",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "agency",
                  "state": _vm.getValidationState(validationContext)
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('employee.agencies')) + " "), _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v(_vm._s(_vm.isEditActive))])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('v-select', {
                attrs: {
                  "options": _vm.agenciesManagerList,
                  "reduce": function reduce(val) {
                    return val.id;
                  },
                  "clearable": false,
                  "disabled": "",
                  "input-id": "agency",
                  "label": "agencyName"
                },
                on: {
                  "open": _vm.openAgenciesManager,
                  "search": _vm.searchAgenciesManager
                },
                scopedSlots: _vm._u([{
                  key: "selected-option",
                  fn: function fn(data) {
                    return [_c('div', {
                      staticClass: "d-flex-center justify-content-between"
                    }, [_c('span', {
                      staticClass: "font-weight-bold d-block text-nowrap"
                    }, [_vm._v(" " + _vm._s(data.agencyName) + " ")])])];
                  }
                }, {
                  key: "option",
                  fn: function fn(data) {
                    return [_c('div', {
                      staticClass: "d-flex-center justify-content-between"
                    }, [_c('span', {
                      staticClass: "font-weight-bold d-block text-nowrap text-uppercase"
                    }, [_vm._v(" " + _vm._s(data.agencyCode) + " ")])])];
                  }
                }, {
                  key: "spinner",
                  fn: function fn(_ref3) {
                    var loading = _ref3.loading;
                    return [loading ? _c('div', {
                      staticClass: "vs__spinner",
                      staticStyle: {
                        "border-left-color": "rgba(88, 151, 251, 0.71)"
                      }
                    }) : _vm._e()];
                  }
                }, {
                  key: "no-options",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.employeeToEdit.agency,
                  callback: function callback($$v) {
                    _vm.$set(_vm.employeeToEdit, "agency", $$v);
                  },
                  expression: "employeeToEdit.agency"
                }
              }), _c('b-form-invalid-feedback', {
                attrs: {
                  "state": _vm.getValidationState(validationContext)
                }
              }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        }), _vm.employeeToEdit.agency.parentAgency ? _c('small', {
          staticClass: "font-italic"
        }, [_vm._v(" Thuộc đại lý: "), _c('span', {
          staticClass: "text-uppercase font-weight-bolder font-normal"
        }, [_vm._v(" " + _vm._s(_vm.employeeToEdit.agency.parentAgency.agencyCode) + " ")]), _vm._v(" (" + _vm._s(_vm.employeeToEdit.agency.parentAgency.agencyName) + ") ")]) : _vm._e()], 1), _vm.employeeToEdit.type === 'BE' ? _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('employee.bookerType'),
            "rules": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "booker-type",
                  "state": _vm.getValidationState(validationContext)
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('employee.bookerType')) + " ")];
                  },
                  proxy: true
                }], null, true)
              }, [_c('v-select', {
                attrs: {
                  "options": _vm.flightTypeOptions,
                  "reduce": function reduce(val) {
                    return val.value;
                  },
                  "clearable": !_vm.readonly,
                  "disabled": _vm.readonly,
                  "input-id": "booker-type"
                },
                scopedSlots: _vm._u([{
                  key: "option",
                  fn: function fn(data) {
                    return [_c('span', [_vm._v(" " + _vm._s(_vm.$te(data.label) ? _vm.$t(data.label) : data.label) + " ")])];
                  }
                }, {
                  key: "selected-option",
                  fn: function fn(data) {
                    return [_c('span', [_vm._v(" " + _vm._s(_vm.$te(data.label) ? _vm.$t(data.label) : data.label) + " ")])];
                  }
                }, {
                  key: "no-options",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.employeeToEdit.bookerType,
                  callback: function callback($$v) {
                    _vm.$set(_vm.employeeToEdit, "bookerType", $$v);
                  },
                  expression: "employeeToEdit.bookerType"
                }
              }), _c('b-form-invalid-feedback', {
                attrs: {
                  "state": _vm.getValidationState(validationContext)
                }
              }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1) : _vm._e(), ['BE', 'SE'].includes(_vm.employeeToEdit.type) && _vm.isRoleF1 ? _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.employeeToEdit.type === 'BE' ? _vm.$t('employee.scheduleCodeBE') : _vm.$t('employee.scheduleCodeSE')
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "scheduleCode"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.employeeToEdit.type === 'BE' ? _vm.$t('employee.scheduleCodeBE') : _vm.$t('employee.scheduleCodeSE')) + " ")];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                directives: [{
                  name: "remove-non-numeric-chars",
                  rawName: "v-remove-non-numeric-chars.allNumber",
                  modifiers: {
                    "allNumber": true
                  }
                }],
                attrs: {
                  "id": "scheduleCode",
                  "number": "",
                  "name": _vm.employeeToEdit.type === 'BE' ? _vm.$t('employee.scheduleCodeBE') : _vm.$t('employee.scheduleCodeSE'),
                  "disabled": _vm.readonly,
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "lazy-formatter": "",
                  "placeholder": _vm.$t('employee.placeholderScheduleCode')
                },
                model: {
                  value: _vm.employeeToEdit.scheduleCode,
                  callback: function callback($$v) {
                    _vm.$set(_vm.employeeToEdit, "scheduleCode", $$v);
                  },
                  expression: "employeeToEdit.scheduleCode"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1) : _vm._e(), _vm.isADM && _vm.isRoleF1 && (_vm.employeeToEdit.type === 'BE' || _vm.isOpenIdBookerForSale && _vm.employeeToEdit.type === 'SE') ? _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('employee.bookerId'),
            "rules": "bookerId|min:5"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "bookerId"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('employee.bookerId')) + " ")];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                attrs: {
                  "id": "bookerId",
                  "name": _vm.$t('employee.bookerId'),
                  "disabled": _vm.readonly,
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "formatter": _vm.trimAllInputAndUpper,
                  "placeholder": _vm.$t('employee.placeholderBookerId')
                },
                model: {
                  value: _vm.employeeToEdit.bookerId,
                  callback: function callback($$v) {
                    _vm.$set(_vm.employeeToEdit, "bookerId", $$v);
                  },
                  expression: "employeeToEdit.bookerId"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1) : _vm._e(), _vm.isShowEmployeeTelegramId ? _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('employee.telegramId'),
            "rules": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "telegramId"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_c('HStack', [_c('IAmIcon', {
                      attrs: {
                        "icon": "telegram",
                        "size": "16",
                        "color": "#40B3E0"
                      }
                    }), _c('div', [_vm._v(" " + _vm._s(_vm.$t('employee.telegramId')) + " ")])], 1)];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                directives: [{
                  name: "remove-non-numeric-chars",
                  rawName: "v-remove-non-numeric-chars.allNumber",
                  modifiers: {
                    "allNumber": true
                  }
                }],
                attrs: {
                  "id": "telegramId",
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "disabled": _vm.readonly,
                  "lazy-formatter": "",
                  "formatter": _vm.trimInput,
                  "debounce": 300,
                  "placeholder": _vm.$t('employee.placeholderTelegramId')
                },
                model: {
                  value: _vm.employeeToEdit.telegramId,
                  callback: function callback($$v) {
                    _vm.$set(_vm.employeeToEdit, "telegramId", $$v);
                  },
                  expression: "employeeToEdit.telegramId"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1) : _vm._e()], 1)], 1)], 1)], 1), _c('div', {
          staticClass: "d-flex justify-content-center"
        }, [_c('b-button', {
          staticClass: "mb-1 mr-50",
          attrs: {
            "variant": "secondary"
          },
          on: {
            "click": _vm.onCancel
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('back')) + " ")]), !_vm.readonly ? _c('b-button', {
          staticClass: "mb-1 ml-50",
          attrs: {
            "variant": "info",
            "disabled": invalid || pristine
          },
          on: {
            "click": function click($event) {
              handleSubmit(_vm.updatedEmployeeHandle(true));
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('save')) + " ")]) : _vm._e()], 1)];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }